import { LocalStore } from '../../utils/LocalStore'
import Shipments from '../Shipment'
import Orders from '../Orders'
import PeerPayments from '../PeerPayments'
import DashboardPage from '../../components/Dashboard'

const Dashboard = (props: any) => {
    let currentProjectInfo = LocalStore.getInstance().read('currentProjectInfo') || {}
    const tenantConfig = currentProjectInfo.tenantConfig || {}
    const orderConfig = tenantConfig.orderConfig || {}
    const shipmentConfig = tenantConfig.shipmentConfig || {}
    const generalConfig = tenantConfig.generalConfig || {}
    if (generalConfig.showDashboard) {
        return <DashboardPage {...props} />
    }
    if (orderConfig.disable) {
        if (shipmentConfig.enable) {
            return <Shipments {...props} />
        } else if (generalConfig.peerPaymentEnabled) {
            return <PeerPayments {...props} />
        }
    }

    return <Orders {...props} />
}

export default Dashboard
