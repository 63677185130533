import MainLayout from '../Layout/MainLayout'
import { withNamespaces } from 'react-i18next'
import { Row, Col, Empty, Spin } from 'antd'
import { LocalStore } from '../../utils/LocalStore'
import { Link } from 'react-router-dom'
import { quantityFormat, moneyCeil, moneyFormat } from '../../utils/stringUtils'
import { useEffect, useState } from 'react'
import UserServices from '../../services/user.service'
import _ from 'lodash'
import DepositModal from '../DepositModal'
import CategoriesService from '../../services/categories.service'

const Dashboard = props => {
    const { t } = props
    const [balance, setBalance] = useState(0)
    const [isDepositModal, setIsDepositModal] = useState(false)
    const [listStatuses, setListStatuses] = useState([])
    const [suggestProducts, setSuggestProducts] = useState([])
    const [loading, setLoading] = useState(false)

    const ordersStatistics = LocalStore.getInstance().read('ordersStatistics') || []

    const statusShow = ['DEPOSITED', 'INITIALIZE', 'PUTAWAY', 'TRANSPORTING', 'READY_FOR_DELIVERY', 'DELIVERING']
    const getBalanceUser = () => {
        UserServices.getBalanceUser((err, res) => {
            if (!err) {
                setBalance(res ? moneyCeil(res.balance) : 0)
            }
        })
    }

    const getOrderStatuses = () => {
        CategoriesService.getOrderStatuses((err, res, headers) => {
            if (!err) {
                setListStatuses(res)
            }
        })
    }

    const getSuggestProducts = async () => {
        try {
            setLoading(true)
            const apiUrl = process.env.REACT_APP_GOODWAY_URL
            const response = await fetch(`${apiUrl}/chase/public/recommend-product?limit=48`)
            let responseData = await response.json()
            setLoading(false)
            setSuggestProducts(responseData)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    https: useEffect(() => {
        getBalanceUser()
        getOrderStatuses()
        getSuggestProducts()
    }, [])

    const renderOrderStatusSearchItem = (item, index, prop) => {
        const ordersStatistics = LocalStore.getInstance().read('ordersStatistics') || []
        return (
            <Col span={8}>
                <div className="mb-2 text-grey-400 text-xs">{item.name}</div>
                <div className="text-2xl text-black-100 font-bold">
                    {prop === 'statuses' && ordersStatistics.find(x => x.status === item.code)
                        ? quantityFormat(ordersStatistics.find(x => x.status === item.code).total)
                        : '0'}
                </div>
            </Col>
        )
    }

    console.log(suggestProducts)

    return (
        <MainLayout
            {...props}
            showProgressBar={true}
            headerTitle={t('dashboard.titleHeader')}
            title={t('dashboard.titleHeader')}
        >
            <div className={'container-common position-re pr-4'}>
                <Row
                    gutter={24}
                    className="mb-8"
                >
                    <Col span={15}>
                        <div className="bg-white rounded-xl p-6">
                            <div className="text-black-100 font-bold text-xl mb-6">{t('dashboard.title')}</div>
                            <div className="w-full">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <div className="p-6 rounded-xl border border-solid border-grey-scale">
                                            <div className=" flex gap-4 items-center mb-2">
                                                <div>
                                                    <i class="fa-solid fa-wallet text-xl px-[10px] py-[6px] text-blue-primary bg-blue-50 rounded-full"></i>
                                                </div>
                                                <div>
                                                    <div className="text-grey-400 font-medium mb-1">
                                                        {t('customer_info.balanceAfter')}
                                                    </div>
                                                    <div
                                                        className={` font-bold text-2xl ${
                                                            balance >= 0 ? 'text-black-100' : 'txt-color-red'
                                                        }`}
                                                    >
                                                        {balance >= 0 ? `${moneyFormat(balance)}` : `- ${moneyFormat(balance)}`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pl-14">
                                                <span
                                                    onClick={() => setIsDepositModal(true)}
                                                    className="text-blue-primary cursor-pointer hover:text-blue-primary-click"
                                                >
                                                    {t('header.deposit')} <i class="fa-regular fa-plus"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="p-6 rounded-xl border border-solid border-grey-scale">
                                            <div className=" flex gap-4 items-center mb-2">
                                                <div>
                                                    <i class="fa-solid fa-warehouse-full text-xl px-[7.5px] py-[6px] text-blue-primary bg-blue-50 rounded-full"></i>
                                                </div>
                                                <div>
                                                    <div className="text-grey-400 font-medium mb-1">
                                                        {t('dashboard.orderInStock')}
                                                    </div>
                                                    <div
                                                        className={` font-bold text-2xl text-black-100
                                                      `}
                                                    >
                                                        {quantityFormat(
                                                            ordersStatistics.find(x => x.status === 'DELIVERY_READY').total
                                                        )}{' '}
                                                        <span className="font-normal text-sm">
                                                            {t('dashboard.orderNeedDelivery')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pl-14">
                                                <Link
                                                    to={'/delivery/create'}
                                                    className="text-blue-primary cursor-pointer hover:text-blue-primary-click"
                                                >
                                                    {t('delivery.create_title')} <i class="fa-regular fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                    <Col span={9}>
                        <div className="bg-white rounded-xl p-6 h-full">
                            <div className="mb-6 flex justify-between items-center">
                                <div className="text-black-100 font-bold text-xl ">{t('dashboard.order')}</div>
                                <div>
                                    <Link
                                        to={'/orders'}
                                        className="text-grey-400 hover:text-black-100"
                                    >
                                        {t('order.view_details')} <i class="fa-regular fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <Row gutter={[16, 24]}>
                                    {listStatuses
                                        ?.filter(i => statusShow?.includes(i?.code))
                                        ?.map((item, index) => renderOrderStatusSearchItem(item, index, 'statuses'))}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="mb-6">
                    <div className="bg-white rounded-xl p-6">
                        <div className="flex justify-between">
                            <div className="text-black-100 font-bold text-xl mb-6">{t('dashboard.suggest')}</div>
                            <a
                                href={'https://www.1688.com/'}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-primary cursor-pointer hover:text-blue-primary-click"
                            >
                                {t('button.loadmore')} <i class="fa-solid fa-arrow-up-right"></i>
                            </a>
                        </div>
                        <Spin spinning={loading}>
                            {suggestProducts?.length > 0 ? (
                                <div>
                                    <Row
                                        gutter={[16, 24]}
                                        className="mb-4"
                                    >
                                        {suggestProducts?.map((item, index) => {
                                            return (
                                                <Col
                                                    lg={4}
                                                    xxl={3}
                                                    key={index}
                                                >
                                                    <a
                                                        href={`https://detail.1688.com/offer/${item?.itemId}.html`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <div className="w-full border border-solid border-grey-scale rounded-xl cursor-pointer hover:border-blue-primary group overflow-hidden">
                                                            <div className="h-[174px] w-full overflow-hidden">
                                                                <img
                                                                    className="w-full h-[174px] rounded-t-xl object-cover group-hover:scale-110 transition duration-500 ease-in-out "
                                                                    src={item?.image}
                                                                    alt={item?.itemId}
                                                                ></img>
                                                            </div>
                                                            <div className="p-3">
                                                                <div className="group-hover:text-blue-primary line-clamp-2 text-black-100 mb-3">
                                                                    {item?.translateName}
                                                                </div>
                                                                <div className="text-sabo-primary text-lg mb-3">
                                                                    {moneyFormat(item.price, 'CNY')}
                                                                </div>
                                                                <div className="text-grey-400 text-xs">
                                                                    {t('dashboard.totalSold', { value: item?.monthSold })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </Col>
                                            )
                                        })}
                                    </Row>

                                    <div className="w-full flex justify-center">
                                        <a
                                            href={'https://www.1688.com/'}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-primary cursor-pointer hover:text-blue-primary-click"
                                        >
                                            {t('button.loadmore')} <i class="fa-solid fa-arrow-up-right"></i>
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <Empty
                                    image={require('../../resources/images/empty-image.png')}
                                    description={t('message.empty')}
                                ></Empty>
                            )}
                        </Spin>
                    </div>
                </div>
            </div>

            <DepositModal
                visible={isDepositModal}
                onClose={() => setIsDepositModal(false)}
            />
        </MainLayout>
    )
}
export default withNamespaces()(Dashboard)
